if (document.querySelector(".strategySwiper")) {

    var swiper = new Swiper(".strategySwiper", {
        loop: true,
        spaceBetween: 8,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            640: {
                spaceBetween: 20,
            },
        },

    });
    var swiper2 = new Swiper(".strategySwiper2", {
        loop: true,
        spaceBetween: 10,

        thumbs: {
            swiper: swiper,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: "true",
        },
    });

}


if (document.querySelector(".historySwiper .swiper")) {
    const swiper = new Swiper(".historySwiper .swiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        freeMode: true,

        navigation: {
            nextEl: ".historySwiper .swiper-button-next",
            prevEl: ".historySwiper .swiper-button-prev",
        },

        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });
}

if (document.querySelector(".lk__swiper .swiper")) {
    const swiper = new Swiper(".lk__swiper .swiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        freeMode: true,

        navigation: {
            nextEl: ".lk__swiper .swiper-button-next",
            prevEl: ".lk__swiper .swiper-button-prev",
        },

        breakpoints: {
            640: {
                slidesPerView: 2,
            },

            1200: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });
}



if (document.querySelector(".investmentsSwiper")) {

    var swiper = new Swiper(".investmentsSwiper2", {
        loop: true,
        spaceBetween: 0,
        slidesPerView: 2,
        freeMode: true,

        allowTouchMove: false,
        breakpoints: {

            576: {
                spaceBetween: 12,
            },
            1200: {
                spaceBetween: 30,
            }
        },

    });
    var swiper2 = new Swiper(".investmentsSwiper", {
        loop: true,
        spaceBetween: 10,
        effect:"fade",
        autoplay: {
            delay: 20000,
            disableOnInteraction: false,
        },


        thumbs: {
            swiper: swiper,
        },

    });

}

if (document.querySelector(".benefits2Swiper")) {

    let swiper = new Swiper(".benefits2Swiper", {
        spaceBetween: 30,
        effect: "fade",

        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
}